var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('modal-template',{attrs:{"open":_vm.isFormDialogOpen,"primaryBtnProps":{
      text: 'Guardar',
      show: true
    },"secondaryBtnProps":{
      text: 'Cerrar',
      show: true
    }},on:{"close":_vm.handleCloseFormDialog,"continue":_vm.handleSaveForm},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-stretch"},[_c('p',{staticClass:"text-h3 align-self-center"},[_vm._v(" "+_vm._s(_vm.formTitles[_vm.formMode] || '')+" ")]),_c('v-form',{ref:"affiliateForm"},[_c('v-text-field',{attrs:{"label":"Nombre","filled":"","rounded":""},model:{value:(_vm.affiliate.name),callback:function ($$v) {_vm.$set(_vm.affiliate, "name", $$v)},expression:"affiliate.name"}}),_c('v-text-field',{attrs:{"label":"Apellidos","filled":"","rounded":""},model:{value:(_vm.affiliate.lastName),callback:function ($$v) {_vm.$set(_vm.affiliate, "lastName", $$v)},expression:"affiliate.lastName"}}),_c('v-text-field',{attrs:{"label":"Email","filled":"","rounded":""},model:{value:(_vm.affiliate.email),callback:function ($$v) {_vm.$set(_vm.affiliate, "email", $$v)},expression:"affiliate.email"}})],1)],1)]},proxy:true}])}),_c('v-col',{staticClass:"py-5",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('action-button',{attrs:{"icon":"mdi-plus","iconLeft":""},on:{"click":_vm.handleOpenFormDialog}},[_vm._v(" Crear miembro ")])],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-sheet',{attrs:{"color":"accent rounded-xl pl-3"}},[_c('v-text-field',{staticClass:"rounded-search",attrs:{"background-color":"wLightGray","prepend-icon":"fa fa-search","color":"white","solo":"","dense":"","placeholder":"Buscar miembro","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.affiliates.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"server-items-length":_vm.totalAffiliates,"headers":_vm.cols,"items":_vm.affiliates,"loading":_vm.loading,"options":_vm.tableOptions,"footer-props":{
        itemsPerPageOptions: [10, 20, 50]
      },"item-key":"_id"},on:{"update:options":_vm.handleUpdateOptions},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row'
              : ''},[_c('div',{staticClass:"d-flex justify-center",class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : ''},[_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.handleOpenUpdateForm(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)])]}}],null,true)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }