<template>
  <v-row class="mt-5" justify="center">
    <modal-template
      @close="handleCloseFormDialog"
      @continue="handleSaveForm"
      :open="isFormDialogOpen"
      :primaryBtnProps="{
        text: 'Guardar',
        show: true
      }"
      :secondaryBtnProps="{
        text: 'Cerrar',
        show: true
      }"
    >
      <template #content>
        <div class="d-flex flex-column align-stretch">
          <p class="text-h3 align-self-center">
            {{ formTitles[formMode] || '' }}
          </p>
          <v-form ref="affiliateForm">
            <v-text-field
              v-model="affiliate.name"
              label="Nombre"
              filled
              rounded
            />
            <v-text-field
              v-model="affiliate.lastName"
              label="Apellidos"
              filled
              rounded
            />
            <v-text-field
              v-model="affiliate.email"
              label="Email"
              filled
              rounded
            />
          </v-form>
        </div>
      </template>
    </modal-template>
    <v-col cols="12" class="py-5">
      <v-row justify="end">
        <v-col cols="12" md="4">
          <action-button @click="handleOpenFormDialog" icon="mdi-plus" iconLeft>
            Crear miembro
          </action-button>
        </v-col>
        <v-col cols="12" md="5">
          <v-sheet color="accent rounded-xl pl-3">
            <v-text-field
              v-model="search"
              background-color="wLightGray"
              prepend-icon="fa fa-search"
              color="white"
              solo
              dense
              placeholder="Buscar miembro"
              hide-details="auto"
              class="rounded-search"
            >
            </v-text-field>
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :server-items-length="totalAffiliates"
        :headers="cols"
        :items="affiliates"
        :loading="loading"
        :options="tableOptions"
        :footer-props="{
          itemsPerPageOptions: [10, 20, 50]
        }"
        @update:options="handleUpdateOptions"
        item-key="_id"
        class="elevation-1"
        v-if="affiliates.length > 0"
      >
        <template #[`item.actions`]="{ item }">
          <td
            :class="
              $vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row'
                : ''
            "
          >
            <div
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row__cell'
                  : ''
              "
              class="d-flex justify-center"
            >
              <v-btn icon color="accent" @click="handleOpenUpdateForm(item)">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import qs from 'query-string';
import moment from 'moment';

import ActionButton from '@/components/shared/ActionButton';
import ModalTemplate from '@/components/shared/ModalTemplate';
import Endpoints from '@/share/Endpoints';
import { DefaultDateFormat } from '@/share/constants';

export default {
  components: {
    ActionButton,
    ModalTemplate
  },
  props: ['affiliationId'],
  data: () => ({
    search: '',
    affiliates: [],
    totalAffiliates: 0,
    loading: false,
    isFormDialogOpen: false,
    formMode: '',
    affiliate: {
      name: '',
      lastname: '',
      email: '',
      id: ''
    },
    formTitles: {
      CREATE: 'Crear Miembro',
      UPDATE: 'Actualizar Miembro'
    },
    tableOptions: {
      page: 1,
      itemsPerPage: 10
    },
    cols: [
      {
        text: 'Nombre',
        value: 'name',
        align: 'center'
      },
      {
        text: 'Apellidos',
        value: 'lastName',
        align: 'center'
      },
      {
        text: 'Email',
        value: 'email',
        align: 'center'
      },
      {
        text: 'Fecha de registro',
        value: 'updatedAt',
        align: 'center'
      },
      {
        text: 'Fecha de expiración',
        value: 'expirationDate',
        align: 'center'
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center'
      },
    ]
  }),
  created() {
    this.getInscribed();
  },
  watch: {
    search() {
      this.getInscribed();
    }
  },
  methods: {
    handleUpdateOptions(opts) {
      this.tableOptions = {
        itemsPerPage: opts.itemsPerPage,
        page: opts.page
      };
      this.getInscribed();
    },
    handleOpenFormDialog() {
      this.isFormDialogOpen = true;
      this.formMode = 'CREATE';
    },
    handleCloseFormDialog() {
      this.isFormDialogOpen = false;
      this.formMode = '';
      this.$refs.affiliateForm.reset();
    },
    handleOpenUpdateForm(item) {
      this.isFormDialogOpen = true;
      this.formMode = 'UPDATE';
      this.affiliate = {
        name: item.name,
        lastName: item.lastName,
        email: item.email,
        id: item._id
      };
    },
    formatAffiliateData({ _id: id, affiliateInformation: info }) {
      return {
        ...info,
        _id: id,
        infoId: info._id,
        expirationDate: moment(info.expirationDate).format(DefaultDateFormat),
        registrationDate: moment(info.registrationDate).format(DefaultDateFormat),
        updatedAt: moment(info.updatedAt).format(DefaultDateFormat)
      };
    },
    async getInscribed() {
      this.loading = true;
      const endpoint = qs.stringifyUrl({
        url: Endpoints.affiliates.replace(/:affiliationId/, this.affiliationId),
        query: {
          page: this.tableOptions.page,
          limit: this.tableOptions.itemsPerPage,
          name: this.search
        }
      });
      const response = await this.axios.get(endpoint);
      //console.log('get affiliates', response);
      if (response.status === 200) {
        const { affiliates } = response.data;
        this.totalAffiliates = response.data.totalDocs;
        this.affiliates = affiliates.map(aff => this.formatAffiliateData(aff));
        this.loading = false;
      }
    },
    handleSaveForm() {
      const mode = this.formMode;
      const functions = {
        CREATE: this.createAffiliate,
        UPDATE: this.updateAffiliate
      };
      functions[mode] && functions[mode]();
    },
    async createAffiliate() {
      if (!this.$refs.affiliateForm.validate()) return;

      const payload = {
        affiliate: {
          order: '',
          affiliation: this.affiliationId,
          affiliationType: '',
          affiliateInformation: {
            name: '',
            lastName: '',
            email: ''
          }
        }
      };
      const response = await this.axios.post(
        Endpoints.affiliatesCreate,
        payload
      );
      //console.log('create affiliate', response);
    },
    async updateAffiliate() {
      if (!this.$refs.affiliateForm.validate()) return;

      const { id } = this.affiliate;
      const payload = {
        affiliate: {
          ...this.affiliate,
          affiliation: this.affiliationId
        }
      };
      const response = await this.axios.put(
        Endpoints.affiliatesUpdate.replace(/:affiliateId/, id),
        payload
      );
      console.log('update affiliate', response);
      if (response.status === 200) {
        const { affiliate } = response.data;
        this.affiliates = this.affiliates.map(
          aff => aff._id === id
            ? 
              {
                ...aff,
                updatedAt: moment(affiliate.updatedAt).format(DefaultDateFormat),
                name: affiliate.name,
                lastName: affiliate.lastName,
                email: affiliate.email
              } 
            : aff
        );
        this.handleCloseFormDialog();
      }
    }
  }
};
</script>