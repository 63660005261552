<template>
  <v-container fluid>
    <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab href="#registration">Formulario de Registro</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>
    <!--FIN Asignación de créditos a acceso-->
  </v-container>
</template>
<script>
import { Inscribed } from './tabs/index';

export default {
  components: {
    Inscribed,
  },
  props: ['affiliationId'],
  data: () => ({
    tab: 'registration'
  })
};
</script>
